<template>
  <div>
    <div v-if="userData.length !== 0">
      <b-card>
        <div class="row justify-content-between">
          <!-- <b-button size="md" variant="success" @click="createData">
            Create Data
          </b-button> -->
          <b-button size="md" variant="success" @click="exportExcel">
            ExportExcel
          </b-button>
        </div>
      </b-card>
      <b-overlay :show="show" rounded="sm">
        <div class="row justify-content-start">
          <div
            class="col-12 col-md-12 col-xl-6"
            v-for="userDatas in userData"
            :key="userDatas.ID"
          >
            <b-card>
              
              <div class="row justify-centent-start">
                <b-overlay :show="imaLoad" rounded="sm">
                  <div v-if="userDatas.smartDataImage != 0">
                    <b-img-lazy
                      width="150px"
                      height="150px"
                      src
                      :onerror="`fetch('${API}view/images/${userDatas.smartDataImage}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                      class="mx-1 mt-0 center"
                    />
                  </div>
                  <div v-else>
                    <b-img-lazy
                      width="150px"
                      height="150px"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                      alt=""
                    />
                  </div>
                </b-overlay>
                <div class="col-12 col-md-8 col-xl-8 ml-1">
                  <li>
                    <b>หมายเลขบัตรประจำตัว :</b> {{ userDatas.cardNumber }}
                  </li>
                  <li>
                    <b>ชื่อ-นามสกุล :</b>
                    {{
                      `${userDatas.thaiTitle} ${userDatas.thaiFirstName} ${userDatas.thaiLastName}`
                    }}
                  </li>
                  <li>
                    <b>FirstName-LastName :</b>
                    {{
                      `${userDatas.engTitle} ${userDatas.engFirstName} ${userDatas.engLastName}`
                    }}
                  </li>
                  <li><b>วันเกิด :</b> {{ userDatas.dateOfBirth }}</li>
                  <li><b>เพศ :</b> {{ userDatas.sex }}</li>
                  <li>
                    <b>ที่อยู่ :</b>
                    {{
                      `${userDatas.address.homeNo || " "} ${
                        userDatas.address.moo || " "
                      }
            ${userDatas.address.subDistrict || " "} ${
                        userDatas.address.district || " "
                      }
            ${userDatas.address.province || " "} ${
                        userDatas.address.postalCode || " "
                      }`
                    }}
                  </li>
                  <b-button
                    size="sm"
                    variant="danger"
                    class="btn mt-1"
                    @click="deleteData(userDatas)"
                  >
                    <feather-icon icon="Trash2Icon" /> Delete
                  </b-button>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </b-overlay>
    </div>

    <div v-else>
      <!-- <b-card>
        <b-button size="md" variant="success" @click="createData">
          Create Data
        </b-button>
      </b-card> -->
      <b-card class="card">NO Data !!!</b-card>
    </div>
  </div>
</template>
  
<script>
import {
  BCard,
  BCardText,
  BLink,
  BButton,
  BImgLazy,
  BOverlay,
  BImg,
} from "bootstrap-vue";
import API from "@/views/API/condb.js";
import axios from "axios";
import _ from "lodash";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BImgLazy,
    BOverlay,
    BImg,
  },
  data() {
    return {
      API: `${API}`,
      token: "",
      userData: [],
      userImage: [],
      show: true,
      imaLoad: false,
    };
  },
  async created() {
    await this.smartIdData();

    if (
      _.isEmpty(localStorage.getItem("access_token")) ||
      _.isEmpty(localStorage.getItem("access_user"))
    ) {
      localStorage.removeItem("access_user");
      localStorage.removeItem("access_token");
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    // createData() {
    //   this.$router.push({
    //     name: "smartIdDataCreate",
    //     params: this.$route.params.id,
    //   });
    // },
    async access_token() {
      return (
        await axios.post(`${API}token`, "", {
          headers: {
            Authorization:
              localStorage.getItem("access_token") &&
              JSON.parse(localStorage.getItem("access_token")),
          },
        })
      ).data.message;
    },
    async deleteData(value) {
      const { smartDataId } = value;
      const { userId } = await JSON.parse(localStorage.getItem("access_user"));
      const { access_token } = await this.access_token();
      const mid = userId;
      console.log(mid)
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const url = `${API}smartData`;
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };
          const data = {
            mId: await mid,
            smartDataId: await smartDataId,
          };
          const res = await axios.delete(url, data, head);
          console.log(res);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Your file has been deleted.",
              icon: "Trash2Icon",
              variant: "success",
            },
          });
          this.smartIdData();
        }
      });
    },

    async smartIdData() {
      const { access_token } = await this.access_token();
      this.token = access_token;
      const { userId } = await JSON.parse(localStorage.getItem("access_user"));
      const mid = userId;
      const uid = this.$route.params.id;
      // console.log("mId", userId);
      // console.log("uId", uid);
      const url = `${API}smartData/${mid}?uId=${uid}&_sort=-1`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      if (_.isEmpty(res.data.message.result)) {
        return;
      }
      this.userData = await res.data.message.result.map((el, index) => {
        return {
          ...el,
          ID: index + 0,
          fullnameTH: `${el.thaiTitle} ${el.thaiFirstName} ${el.thaiLastName}`,
          fullnameEN: `${el.engTitle} ${el.engFirstName} ${el.engLastName}`,
          fullAddress: `${el.address.homeNo} ${el.address.moo} ${el.address.subDistrict} ${el.address.district} ${el.address.province} ${el.address.postalCode} ${el.address.country}`,
        };
      });

      this.show = false;
    },
    async imageBase64() {
      const { access_token } = await this.access_token();
      const head = {
        responseType: "arraybuffer",
        headers: {
          Authorization: await access_token,
        },
      };
      this.userData.map(async (el, index) => {
        console.log(el.smartDataImage);

        if (el.smartDataImage) {
          const url = `${API}view/images/${el.smartDataImage[0]}`;
          const res = await axios.get(url, head);

          var u8 = new Uint8Array(arrayBuffer);
          var b64encoded = btoa(String.fromCharCode.apply(null, u8));

          this.userImage.push(b64encoded);
        }
      });

      this.imaLoad = false;
    },

    exportExcel() {
      saveExcel({
        data: this.userData,
        fileName: "Smart Id Data Info",
        columns: [
          {
            title: "Smart Id Data Info",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "หมายเลขบัตรประจำตัว",
                field: "cardNumber",
              },
              {
                title: "ชื่อ-นามสกุล",
                field: "fullnameTH",
              },
              {
                title: "FirstName-LastName",
                field: "fullnameEN",
              },
              {
                title: "วันเกิด",
                field: "dateOfBirth",
              },
              {
                title: "เพศ",
                field: "sex",
              },
              {
                title: "ที่อยู่",
                field: "fullAddress",
              },
              {
                title: "Register Date Time",
                field: "registerDate",
              },
            ],
          },
        ],
      });
    },
  },
};
</script>
  
<style>
</style>
  